.searchBarWrapper {
  box-sizing: border-box;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.searchBarWrapper input[type="text"] {
  box-sizing: border-box;
  margin-left: 25px;
  border: 1px solid #c2d7ff;
  width: 630px;
  height: 35px;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  padding-left: 10px; /* placeholder와 input의 왼쪽 경계와의 간격 */
  color: #97aacf;
}

.searchBarWrapper input[type="text"]:focus {
  outline: none;
  border-color: #3F68BA;
}

.searchBarWrapper input[type="text"]::placeholder {
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  opacity: 1; /* 일부 브라우저에서 placeholder의 투명도가 기본적으로 설정되어 있을 수 있으므로 명시적으로 설정 */
  color: #97aacf;
}

.searchBarWrapper button {
  width: 80px;
  height: 35px;
  border: 1px solid #c2d7ff;
  background-color: #2365EA;
  margin-left: 10px;

  font-family: Pretendard, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  color: white;
  cursor: pointer;
}

.searchBarWrapper button:hover {
  background-color: #2a5173;
}
