.userNameContainer {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  height: 70px;
  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: flex-start;
  padding-left: 25px; /* 글자를 80px 옆으로 보내기 */
  background-color: #082357; /* 배경 색상 추가 */
  position: absolute; /* 추가 */
  bottom: 0; /* 추가 */
  width: 100%; /* 추가: 부모 컨테이너의 너비에 맞게 설정 */
}

.userNameContainer .profileImage {
  margin-right: 15px; /* 오른쪽 마진 설정 */
}

.userNameContainer .logoutButton {
  cursor: pointer; /* 손 모양 커서로 변경 */
  margin-right: 20px; /* 우측 마진 추가 */
  margin-left: auto; /* 왼쪽에 가능한 모든 공간을 마진으로 채움 */
}

.signoutButton {
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
  border: none;
  background: none;
}

.signoutButton img:hover {
  filter: brightness(50%) saturate(0);
}