.addButton {
  width: 160px;
  height: 35px;
  background-color: #2365ea;
  border: none; /* 기본 버튼 스타일 제거 */
  color: #f0f5ff; /* 글자 색상 */

  font-family: Pretendard, sans-serif;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer; /* 마우스를 올렸을 때 손가락 모양으로 변경 */
}

.addButton:hover {
  background-color: #1a4cb8; /* 선택적: 버튼에 마우스를 올렸을 때의 배경색 */
}

.addButton:active {
  background-color: #123a8f; /* 선택적: 버튼을 클릭했을 때의 배경색 */
}
