.menuItem {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: 280px;
  height: 70px;
  padding-left: 25px; /* 글자를 25px 옆으로 보내기 */

  display: flex;
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: flex-start;

  color: #f6faff66; /* 액티브하지 않을 때의 글자색 */
}

.menuIcon {
  margin-right: 15px; /* 오른쪽 마진 설정 */
  width: 28.5px;       /* 너비 설정 */
  height: 28.5px;      /* 높이 설정 */
}

.activeMenuItem {
  background-color: #225fd9; /* 원하는 색깔 */
  color: #ffffff; /* 액티브한 경우의 글자색 */
}
