/* 화면 전체를 덮는 뒷 배경. 어둡게 처리 */
.choice-modal-view-background {
  position: fixed;
  top: 0;
  left: 280px; /* Sidebar의 너비만큼 좌측에서 떨어뜨림 */
  width: calc(100vw - 280px); /* 전체 화면 너비에서 Sidebar의 너비를 뺀 값 */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 실제 모달 내용이 들어가는 부분 */
.choice-modal-view {
  background-color: #eff5ff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 900px; /* 원하는 너비로 조정 가능 */
  height: 390px;
  max-width: 90%; /* 화면 크기에 따라 최대 크기 제한 */
  flex-direction: column;
  align-items: center;
  justify-content: space-around; /* 버튼 간격 조절 */
}

.choice-modal-view .choice-modal-view-header {
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard;
  font-size: 20px; /* 글자 크기 조절 */
  font-weight: 500; /* 글자 굵기 조절 */
  color: #051b46;
  border-bottom: 1px solid #c2d7ff;
  position: relative;
}

.choice-modal-view .close-button {
  position: absolute;
  top: 50%; /* 상단에서부터 50% 위치에 배치 */
  right: 30px; /* 오른쪽에서 10px 떨어진 위치에 배치 */
  cursor: pointer;
  font-weight: bold;
  transform: translateY(-50%); /* 수직 중앙 정렬 보정 */
}

.choice-modal-view .button-group {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 47px;
}

.choice-modal-view .choice-modal-button {
  box-sizing: border-box;
  margin: 50px 0;
  border: 1px solid #c2d7ff;
  width: 380px;
  height: 210px;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 700;
  color: #051b46;
}

.choice-modal-view .button-image {
  width: 80px; /* 이미지 크기를 조정하세요 */
  height: 80px; /* 이미지 크기를 조정하세요 */
  margin-bottom: 15px; /* 이미지와 텍스트 사이의 간격을 조정하세요 */
}

.button-description {
  font-size: 12px; /* 설명 텍스트의 크기를 조정하세요 */
  color: #626f89; /* 설명 텍스트의 색상을 조정하세요 */
  font-family: Pretendard;
  font-weight: 600;
  margin: 0; /* 기본 마진을 없앰 */
  margin-top: 15px;
}
