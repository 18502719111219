.direct-add-modal-view-background {
  position: fixed;
  top: 0;
  left: 280px; /* Sidebar의 너비만큼 좌측에서 떨어뜨림 */
  width: calc(100vw - 280px); /* 전체 화면 너비에서 Sidebar의 너비를 뺀 값 */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct-add-modal-view {
  background-color: #eff5ff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 1100px;
  height: 560px;
  max-width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
}

.direct-add-modal-view .question-list-container {
  flex: 1;
}

.direct-add-modal-view .qna-input-container {
  flex: 4;
}

.direct-add-modal-view .close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  width: 20px; /* SVG 아이콘의 너비 (필요에 따라 조절) */
  height: 20px; /* SVG 아이콘의 높이 (필요에 따라 조절) */
}

/* 로딩 스피너를 감싸는 div에 대한 CSS */
.direct-add-modal-view .loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EFF5FF;
  z-index: 9999; /* 다른 요소 위에 나타나게 함 */
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  flex-direction: column; /* 자식 요소를 세로로 배열 */
}

/* 로딩 스피너에 대한 CSS */
.direct-add-modal-view .loading-spinner {
  width: 150px; /* 스피너 크기 */
  height: 150px; /* 스피너 크기 */
  animation: spin 2s linear infinite; /* 회전 애니메이션 */
}

/* 회전 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  color: #97AACF;
  margin-top: 20px; /* 텍스트와 이미지 사이의 간격 */
}