.qna-input-view {
  display: flex;
  flex-direction: column; /* 위아래로 정렬되게 함 */
  align-items: stretch; /* 자식 요소들이 꽉 차게 함 */
}

.qna-input-view textarea[placeholder="질문 입력하기..."],
.qna-input-view textarea[placeholder="답변 입력하기..."] {
  box-sizing: border-box;
  width: 100%;
  border: none; /* border 제거 */
  outline: none; /* focus될 때 나타나는 outline 제거 */
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  resize: none;
  color: #051b46;
  border-bottom: 1px solid #c2d7ff;
}

.qna-input-view textarea[placeholder="질문 입력하기..."] {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 28px 50px 20px 30px; /* 변경된 부분 */
}

.qna-input-view textarea[placeholder="답변 입력하기..."] {
  height: 300px;
  background-color: #eff5ff;
  padding: 30px 50px 30px 30px; /* 변경된 부분 */
}

.qna-input-view textarea::placeholder {
  color: #97aacf;
}

.qna-input-view .buttons-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.qna-input-view .save-btn {
  margin-left: auto;  /* This will push the button to the right */
  width: 115px;
  height: 40px;
  background-color: #2365ea;
  color: #f0f5ff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.qna-input-view .save-btn:hover {
  background-color: #1651b7; /* 약간 어두운 색으로 변경하여 hover 효과 추가 */
}

.qna-input-view .tag-wrapper {
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  border-bottom: 1px solid #c2d7ff;
  padding: 0 30px;
}

.qna-input-view .tag-title {
  box-sizing: border-box;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #97aacf;
  box-sizing: border-box;
}
