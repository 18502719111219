.excel-add-modal-view {
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 910px;
  height: 400px;
  max-width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.excel-add-modal-view .excel-header {
  height: 80px;
  border-bottom: 1px solid #c2d7ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.excel-add-modal-view .excel-header p {
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  color: #051b46;
  margin: 0; /* 기본 마진 제거 */
}

.excel-add-modal-view .close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  width: 20px; /* SVG 아이콘의 너비 (필요에 따라 조절) */
  height: 20px; /* SVG 아이콘의 높이 (필요에 따라 조절) */
}

.excel-add-modal-view .excel-body {
  display: flex;
  flex-direction: row;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  height: calc(
    100% - 80px
  ); /* .excel-modal-content의 높이에서 .excel-header의 높이를 뺀 값 */
  width: 100%;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  color: #626f89;
}

.excel-add-modal-view .upload-excel-file {
  width: 380px;
  height: 240px;
  border: 1px solid #c2d7ff;
  box-sizing: border-box;
  margin: 30px;
  background-color: #eff5ff;

  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  flex-direction: column;
}

.excel-add-modal-view .upload-excel-file p {
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #626F89;
}

.excel-add-modal-view .upload-excel-file .upload-button {
  background-color: #2365EA;
  width: 115px;
  height: 36px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Pretendard', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #F0F5FF;
}


.excel-add-modal-view .description-excel {
  width: 380px;
  height: 240px;
  box-sizing: border-box;
  margin: 30px;
}


/* 파일이 hover됐을 때의 스타일 */
.excel-add-modal-view .upload-excel-file.dragging {
  background-color: #d0e0ff; /* 또는 원하는 색상 */
  border: 2px dashed #2365ea; /* 점선 테두리 */
}

.excel-add-modal-view .upload-excel-file img,
.excel-add-modal-view .upload-excel-file p {
  pointer-events: none;
}

.excel-add-modal-view .description-excel img {
  width: 330px;
  height: auto;
  margin: 0 auto; /* 이미지를 중앙 정렬 */
  display: block; /* 이 부분이 중요합니다. inline 요소는 margin: auto를 통해 중앙 정렬이 안됩니다. */
}

.excel-add-modal-view .description-excel p.excel-title {
  font-size: 16px; /* 글자 크기 설정 */
  font-weight: 600; /* 글자 굵기 설정 */
  text-align: center;
  color: #051B46;
  margin: 0;
  margin-bottom: 10px;
}

.excel-add-modal-view .description-excel p.margin-side {
  margin-left: 20px;
}

.excel-add-modal-view .upload-excel-file .selected-file {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #626F89;
}


/* 로딩 스피너를 감싸는 div에 대한 CSS */
.excel-add-modal-view .loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EFF5FF;
  z-index: 9999; /* 다른 요소 위에 나타나게 함 */
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  flex-direction: column; /* 자식 요소를 세로로 배열 */
}

/* 로딩 스피너에 대한 CSS */
.excel-add-modal-view .loading-spinner {
  width: 150px; /* 스피너 크기 */
  height: 150px; /* 스피너 크기 */
  animation: spin 2s linear infinite; /* 회전 애니메이션 */
}

/* 회전 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  color: #97AACF;
  margin-top: 20px; /* 텍스트와 이미지 사이의 간격 */
}