.menuNameText {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: #3f68ba;
  font-family: "GoyangDeogyang EB", sans-serif;
  margin-left: 30px;
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: flex-start; /* 텍스트를 수평 시작점에 정렬 */
  height: 100%; /* 부모 컨테이너의 높이를 채움 */
}
