.data-add-container {
  margin-left: 5px;
}

.data-add-container .close-confirm-modal {
  position: fixed;
  top: 0;
  left: 280px; /* Sidebar의 너비만큼 좌측에서 떨어뜨림 */
  width: calc(100vw - 280px); /* 전체 화면 너비에서 Sidebar의 너비를 뺀 값 */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.data-add-container .modal-content {
  width: 380px;
  height: 210px;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #c2d7ff;
  box-sizing: border-box;
}

.data-add-container .modal-content p {
  margin: 0;
  padding: 0;
}

.data-add-container .modal-icon {
  font-size: 2em;
  font-weight: bold;
}

.data-add-container .modal-text {
  font-size: 16px;
  margin-top: 10px;
}

.data-add-container .button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.data-add-container .button {
  flex: 1;
  height: 60px;
  background-color: white; /* 버튼의 배경색을 흰색으로 */
  color: #225fd9; /* 버튼 내부 글자색 */
  font-family: Pretendard; /* 글꼴 설정 */
  font-size: 15px; /* 글자 크기 */
  font-weight: 700; /* 글자 두께 */
  border: none;
  cursor: pointer;
  border-top: 1px solid #c2d7ff;
}

.data-add-container .button:first-child {
  border-right: 1px solid #c2d7ff; /* 첫 번째 버튼의 오른쪽에 border 설정 */
}

.data-add-container .modal-text {
  font-family: Pretendard; /* 글꼴 설정 */
  font-size: 20px; /* 글자 크기 */
  font-weight: 700; /* 글자 두께 */
  color: #051b46; /* 글자색 */
  text-align: center; /* 중앙 정렬 */
  margin-top: 10px;
}

.data-add-container .modal-icon img {
  width: 40px;
  height: 40px;
  margin: 20px auto; /* 위아래 마진 20px, 좌우 마진은 auto로 설정하여 중앙 정렬 */
  display: block; /* 이미지를 block 요소로 만들어 auto 마진이 작동하게 함 */
}
