.question-list-container {
  background-color: #d9e6ff;
  max-height: 100%;
  overflow-y: auto;
}

.question-list-title {
  height: 60px;
  box-sizing: border-box;
  color: #051b46;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  display: flex; /* Flexbox를 사용하기 위한 설정 */
  align-items: center; /* 위아래로 중앙 정렬 */
  justify-content: flex-start; /* 좌우로는 좌측 정렬 */
  padding-left: 20px; /* 좌측으로 20px의 패딩을 추가하여 마진 효과를 줍니다 */
}

/* 테이블 전체 설정 */
.question-list-container table {
  width: 100%;
  border-collapse: collapse; /* 경계선 겹치기 */
  table-layout: fixed;
}

/* 각 행 설정 */
.question-list-container tr.question-item {
  height: 45px;
  font-family: Pretendard;
  color: #051b4699;
  font-size: 15px;
  font-weight: 500;
}

/* 각 셀 설정 */
.question-list-container td {
  padding: 0px 20px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.question-list-container tr.selected td {
  background-color: #eff5ff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 700;
  color: #051b46;
}

.question-list-container tr:hover td {
  background-color: #eff5ff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 700;
  color: #051b46;
  cursor: pointer;
}

.question-list-container .buttons-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  box-sizing: border-box;
}

.question-list-container .continue-btn {
  width: 100%;            /* Width */
  height: 45px;            /* Height */
  background-color: #2365EA; /* Background color */
  color: white;            /* Text color */
  font-family: Pretendard; /* Font family */
  font-size: 15px;         /* Font size */
  font-weight: 600;        /* Font weight */
  border: none;            /* Remove border */
  outline: none;           /* Remove outline */
  cursor: pointer;         /* Pointer cursor on hover */
  text-align: center;      /* Center text */
  display: flex;           /* Use flexbox for easier centering */
  justify-content: center; /* Center text horizontally */
  align-items: center;     /* Center text vertically */
}

.question-list-container .continue-btn:hover {
  background-color: #1651b7; /* Slightly darker color on hover */
}

.question-list-container img.delete-icon {
  width: 10px;
  height: 10px;
}

.question-list-container td.delete-icon-container {
  text-align: right;
  width: 5%;
  text-overflow: clip;
}