@font-face {
  font-family: "GoyangDeogyang EB";
  src: url("../../assets/fonts/GOYANGDEOGYANG EB.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.company-name {
  box-sizing: border-box;
  width: 278px;
  height: 75px;
  background-color: transparent; /* 배경색을 투명하게 설정 */
  border-bottom: 1px solid #7cb6ff40; /* 하단에 1px 흰색 선 */
  display: flex; /* flexbox를 사용하여 내용을 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: flex-start; /* 가로 좌측 정렬 */
  align-items: center; /* 세로 중앙 정렬 유지 */
  margin-left: 30px; /* 좌측 마진 20px 추가 */

  /* 글꼴 스타일 설정 */
  font-family: "GoyangDeogyang EB";
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;

}
