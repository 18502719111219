.qna-modal-update-container {
  background-color: #eff5ff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 900px;
  height: 560px;
  max-width: 90%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column; /* 위아래로 정렬되게 함 */
  align-items: stretch; /* 자식 요소들이 꽉 차게 함 */
}

.qna-modal-update-container textarea[placeholder="질문 입력하기..."],
.qna-modal-update-container textarea[placeholder="답변 입력하기..."] {
  box-sizing: border-box;
  width: 100%;
  border: none; /* border 제거 */
  outline: none; /* focus될 때 나타나는 outline 제거 */
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  resize: none;
  color: #051b46;
  border-bottom: 1px solid #c2d7ff;
}

.qna-modal-update-container textarea[placeholder="질문 입력하기..."] {
  box-sizing: border-box;
  height: 80px;
  padding: 28px 50px 20px 60px; /* 변경된 부분 */
}

.qna-modal-update-container textarea[placeholder="답변 입력하기..."] {
  height: 300px;
  background-color: #eff5ff;
  padding: 30px 50px 30px 60px; /* 변경된 부분 */
}

.qna-modal-update-container textarea::placeholder {
  color: #97aacf;
}

.qna-modal-update-container .buttons-container {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: flex-end;
}

.qna-modal-update-container .update-btn {
  width: 90px;
  height: 40px;
  background-color: #2365ea;
  color: #f0f5ff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
  border: none;
  cursor: pointer;
}

.qna-modal-update-container .update-btn:active,
.qna-modal-update-container .update-btn:focus {
  border: none;  /* 테두리를 제거 */
  outline: none;  /* 클릭되었을 때 나타나는 outline을 제거 */
}

.qna-modal-update-container .update-btn:hover {
  background-color: #1651b7; /* 약간 어두운 색으로 변경하여 hover 효과 추가 */
}
.qna-modal-update-container .delete-btn {
  width: 90px;
  height: 40px;
  background-color: #c2d7ff;
  color: #7fa4ff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.qna-modal-update-container .delete-btn:hover {
  background-color: #a3b8d7;
}

.qna-modal-update-container .tag-wrapper {
  width: 100%;
  height: 110px;
  box-sizing: border-box;
  border-bottom: 1px solid #c2d7ff;
  padding: 0 30px;
}

.qna-modal-update-container .tag-title {
  box-sizing: border-box;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #97aacf;
  box-sizing: border-box;
}

.qna-modal-update-container .close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  width: 20px; /* SVG 아이콘의 너비 (필요에 따라 조절) */
  height: 20px; /* SVG 아이콘의 높이 (필요에 따라 조절) */
}

.qna-modal-update-container .label {
  position: absolute;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 20px;
  color: #051b46;
}

.qna-modal-update-container #question-label {
  top: 28px; /* 위치를 조정하고 싶다면 값을 변경하세요. */
  left: 30px; /* 위치를 조정하고 싶다면 값을 변경하세요. */
}

.qna-modal-update-container #answer-label {
  top: 110px; /* 위치를 조정하고 싶다면 값을 변경하세요. */
  left: 30px; /* 위치를 조정하고 싶다면 값을 변경하세요. */
}


/* 로딩 스피너를 감싸는 div에 대한 CSS */
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EFF5FF;
  z-index: 9999; /* 다른 요소 위에 나타나게 함 */
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  flex-direction: column; /* 자식 요소를 세로로 배열 */
}

/* 로딩 스피너에 대한 CSS */
.loading-spinner {
  width: 150px; /* 스피너 크기 */
  height: 150px; /* 스피너 크기 */
  animation: spin 2s linear infinite; /* 회전 애니메이션 */
}

/* 회전 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  color: #97AACF;
  margin-top: 20px; /* 텍스트와 이미지 사이의 간격 */
}