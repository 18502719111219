.dataTable {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  width: calc(100% - 50px);
  margin: 0 25px;
  background-color: #ffffff;
  table-layout: fixed;
}

.dataTable th,
.dataTable td {
  margin: 0;
  padding: 0;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

.dataTable th {
  box-sizing: border-box;
  height: 60px;
  border-bottom: 2px solid #3f68ba;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #99accf;
}

/* 각 열의 너비를 비율에 맞게 설정 */
.dataTable th:nth-child(1),
.dataTable td:nth-child(1) {
  /* 질문 열 */
  width: 35%;
}

.dataTable th:nth-child(2),
.dataTable td:nth-child(2) {
  /* 답변 열 */
  width: 35%;
}

.dataTable th:nth-child(3),
.dataTable td:nth-child(3) {
  /* 태그 열 */
  width: 15%;
}

.dataTable th:nth-child(4),
.dataTable td:nth-child(4) {
  /* 시간 열 */
  width: 15%;
}

.dataTable tbody tr:hover {
  cursor: pointer;
  background-color: #f0f5ff; /* 마우스를 올렸을 때 배경 색상 변경 (선택 사항) */
}

.dataTable td {
  box-sizing: border-box;
  border-bottom: 1px solid #c2d7ff;
  height: 55px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  color: #334d83;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 시간 열에 대한 글자색 설정 */
.dataTable td:nth-child(4) {
  color: #0d2149a6;
}

.dataTable .tag-item {
  background-color: #c2d7ff;
  color: #5a6c90;
  border-radius: 2px;
  font-size: 13px;
  padding: 4px 8px; /* 위아래로 4px, 좌우로 8px의 패딩 추가 */
  display: inline-block; /* 인라인 요소들과 같은 라인에 위치하도록 설정 */
  margin-right: 4px; /* 태그 사이에 간격을 주기 위해 */
  white-space: nowrap; /* 태그 내용이 넘치더라도 줄바꿈 되지 않게 설정 */
}
