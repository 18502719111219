@font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/Pretendard.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard';
    background-color: #EFF5FF;
    box-sizing: border-box;

}

.modal-background {
  position: fixed;
  top: 0;
  left: 280px; /* Sidebar의 너비만큼 좌측에서 떨어뜨림 */
  width: calc(100vw - 280px); /* 전체 화면 너비에서 Sidebar의 너비를 뺀 값 */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 스크롤바의 트랙 (배경) */
::-webkit-scrollbar {
  background-color: #C2D7FF; /* 스크롤바 배경 색상 */
}

/* 스크롤바의 핸들 (가로 스크롤바) */
::-webkit-scrollbar-thumb {
  background: #7FA4FF;  /* 스크롤바 색상 */
}

/* 스크롤바의 핸들 (세로 스크롤바) */
::-webkit-scrollbar-thumb:vertical {
  background: #7FA4FF;  /* 스크롤바 색상 */
}

/* 핸들에 마우스를 올렸을 때 */
::-webkit-scrollbar-thumb:hover {
  background: #5A80FF;  /* 호버 시 스크롤바 색상 */
}

/* SnackBar의 메시지 관련된 css 코드 */
.MuiAlert-message {
  font-family: 'Pretendard';
  font-weight: 600;
}