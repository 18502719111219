.customSnackbarSuccess {
    background-color: #39B60D !important;
    color: #F0F5FF !important;
    font-family: 'Pretendard', sans-serif !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    width: 500px !important;
    height: 40px !important;
    border-radius: 6px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }