.management-console-container {
  width: 100%;
  margin-left: 280px;
}

.data-add-count-wrapper {
  box-sizing: border-box;
  margin: 0 25px;
  height: 105px;
  display: flex; /* flexbox를 사용 */
  align-items: center; /* 컴포넌트들을 세로 중앙으로 정렬 */
}
