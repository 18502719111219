.tag-selector-view {
  position: relative;
  width: 200px;
  box-sizing: border-box;
  margin-left: 20px;
  width: 140px;
  height: 35px;
}

.tag-selector-view .tag-selector-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 7px;
  padding-left: 10px;
  background-color: white;
  border: 1px solid #c2d7ff;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  color: #3f68ba;
  transition: background-color 0.3s;
}

.tag-selector-view .tag-selector-button:hover {
  background-color: #f1f5f9;
}

.tag-selector-view .arrow {
  margin-left: 10px;
}

.tag-selector-view .tag-list {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #c2d7ff;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.tag-selector-view .tag-item {
  display: block;
  width: 100%;
  padding: 8px;
  text-align: left;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  transition: background-color 0.3s;
  color: #626f89;
}

.tag-selector-view .tag-item:hover {
  background-color: #c2d7ff;
  color: #3f68ba;
}
