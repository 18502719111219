.appContainer {
  display: flex;
  width: 100%;
}

.SidebarContainer {
  width: 25%;
  padding: 20px;
}

.DataTableContainer {
  width: 75%;
  padding: 20px;
}
